.el-rate__icon {
  margin-right: 0;
}
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.el-form .el-form-item {
  margin-bottom: 18px;
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
}
.zjzw > div {
  overflow-y: auto;
  overflow-x: hidden;
  width: 33.33%;
  margin-right: 4%;
}
.zjzw > div > div {
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.qwhy {
  height: 450px;
  display: flex;
  justify-content: space-between;
}
.qwhy > div {
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  margin-right: 4%;
}
.qwhy > div > div {
  line-height: 26px;
  padding-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
